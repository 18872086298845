import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';

function Head() {
  const translate = useTranslate();

  const description = translate(
    'Discover thousands of top playlists with songs handcrafted by your favorite personalities and music experts on iHeartRadio.',
  );
  const keywords = translateKeywords(
    translate,
    'Playlist, iHeartRadio, Top Playlists, Best Playlists, Music Playlists, Online Playlists',
  );
  const title = translate('Listen to the Best Online Music Playlists');

  return (
    <>
      <GenericHead
        description={description}
        ogType="music.playlist"
        title={title}
      />
      <Helmet>
        <meta content={title} itemProp="name" />
        <meta content={keywords} name="keywords" />
      </Helmet>
    </>
  );
}

export default Head;
